@import "core/index.scss";
.section.footer {
	color: $color-text-dark;

	&:not(.plain) {
		min-height: 100vh;
	}
	// content-visibility: auto;

	// @include media {
	// 	padding-bottom: 6rem;
	// }

	.footer-app-btn {
		.img {
			height: 2.5rem;
			width: 10.7rem;
			// width: auto;
			max-width: none;
			margin-bottom: .2rem;
		}

		@include media {
			margin-right: 1rem;
			flex: 1 0 0;
		}
	}

	.footer-main {
		@include font-medium;
		padding: 0 4rem;
		background-color: $color-pink-bg-light;

		@include media {
			padding: 0;
		}
	}

		.main-menu {
			display: flex;
			justify-content: space-between;
			padding: 3.8rem 0 2.8rem;

			@include media {
				display: block;
				padding: 0 0 0;
			}
		}
			.menu-col {
				@include mediaMin {
					max-width: 35%;

					> .menu-section + .menu-section {
						margin-top: 2rem;
					}
				}

				@include media {
					// + .menu-col .menu-section,
					// + .menu-section {
					// 	border-top: .1rem solid rgba($color-text, .2);
					// }

					.menu-section + .menu-section,
					+ .menu-col .menu-section {
						border-top: .1rem solid rgba($color-text, .2);
					}

				}
			}

			.menu-section {
				@include media {
					// + .menu-section,
					// + .menu-col .menu-section {
					// 	border-top: .1rem solid rgba($color-text, .2);
					// }

					&.active {
						.section-list {
							display: block;
						}

						&.show {
							.section-list {
								opacity: 1;
								transform: translate3d(0, 0, 0);
							}
						}
					}
				}

				&.social {
					@include media {
						border-top: none!important;
						padding-top: 1.5rem;
					}

					.section-list {
						@include font-bold;
						padding-top: 1.2rem;
						// color: $color-white;

						@include media {
							display: block;
							opacity: 1;
							transform: translate3d(0, 0, 0);
							padding-top: 0;

							.list-row {
								display: flex;
								justify-content: flex-start;
							}

								.row-col {
									padding-top: 0!important;
								}

							.col-link {
								font-size: 1.3rem;							

								span {
									display: none;
								}
							}
						}

						.row-col {
							padding: .7rem 0;
						}
					}
				}
			}

				.section-title {
					margin-bottom: .7rem;
				}

				.section-title, .section-btn {
					@include font-semibold;
					display: block;
					font-size: 1.5rem;
					width: 100%;

					@include media {
						padding: 2.8rem 0;
						margin-bottom: 0;
					}
				}

				.section-btn {
					display: flex;
					justify-content: space-between;

					&:after {
						@include icon('angle-down');
						font-size: 1rem;
					}
				}

				.section-list {
					display: flex;
					color: rgba($color-text, .7);

					@include media {
						display: none;
						opacity: 0;
						transform: translate3d(0, -1rem, 0);
						transition: transform $transition-hover ease, opacity $transition-hover ease;
						padding: 0 0 2rem;
						margin-top: -1rem;
					}

					// &.social {
					// }
				}

					.list-row {
						+ .list-row {
							
							@include mediaMin {
								margin-left: 7rem;
							}
						}
					}

						.row-col {
							padding: .6rem 0 .7rem;
							line-height: 1em;

							&:nth-child(5n+2) {
								.col-link {
									&.placeholder {
										width: 14rem;
									}
								}		
							}
							&:nth-child(5n+3) {
								.col-link {
									&.placeholder {
										width: 12rem;
									}
								}		
							}
							&:nth-child(5n+4) {
								.col-link {
									&.placeholder {
										width: 11rem;
									}
								}		
							}
							&:nth-child(5n+5) {
								.col-link {
									&.placeholder {
										width: 13rem;
									}
								}		
							}

							> a {
								transition: color $transition-hover ease;

								@include mediaMin {
									&:hover {
										color: $color-text-blue;
									}
								}
							}
						}

							.col-link {
								display: inline-flex;
								align-items: center;
								justify-content: flex-start;

								&.placeholder {
									width: 10.5rem;
									height: 1.5rem;
								}
								
								i {
									background-color: $color-pink;
									width: 4rem;
									height: 4rem;
									font-size: 1.8rem;
									color: $color-white;
									border-radius: 50%;
									margin-right: 1rem;
									display: inline-flex;
									align-items: center;
									justify-content: center;
								}
							}

		.main-mid {
			height: 10rem;
			box-sizing: content-box;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-top: .1rem solid rgba($color-text, .2);
			
			+ .main-bottom {
				border-top: .1rem solid rgba($color-text, .2);

				&:not(.has-app) {
					padding-top: 0;
					border-top: none;
				}
			}
			
			@include media {
				display: block;
				height: auto;
				padding: 2rem 0;
			}
		}

			.mid-sidetitle {
				@include font-semibold;
				margin-right: 2.4rem;

				@include media {
					display: block;
					width: 100%;
					margin: 0 0 2rem 0;
				}
			}

			.mid-section {
				display: flex;
				flex-flow: row wrap;
				justify-content: flex-start;
				align-items: center;

				@include mediaMin {
					> .btn + .btn {
						margin-left: .9rem;
					}
				}

				@include media {
					justify-content: space-between;

					> .btn {
						&:not(:last-child) {
							margin-bottom: 1rem;
						}

						&.nude {
							margin: 0 auto;
						}
					}

					&.right {
						margin-top: 1rem;
						padding-top: 2rem;
						border-top: .1rem solid rgba($color-text, .2);
					}
				}
			}

		.main-bottom {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 2rem 0;

			@include media {
				display: block;
			}
		}

			.bottom-section {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 3rem;

				+ .bottom-section {
					@include mediaMin {
						&:before {
							@include pseudo;
							position: absolute;
							left: 0;
							top: 50%;
							transform: translate3d(0, -50%, 0);
							height: 5rem;
							background-color: rgba($color-text, .2);
							width: .1rem;
						}
					}

					@include media {
						margin-top: 2rem;
					}
				}

				@include mediaMin {
					> * + * {
						margin-left: 2rem;
					}
				}
	
				@include media {
					// height: auto;
					flex-flow: row wrap;
					justify-content: flex-start;
					padding: 0;

					&.etbis {
						justify-content: center;
					}
				}
			}

			.bottom-title {
				@include media {
					width: 100%;
					margin-bottom: 2rem;
				}
			}

			.bottom-qr {
				@include font-regular;
				display: flex;
				align-items: center;
				font-size: 1rem;
				line-height: 1.2em;

				@include media {
					margin-top: 2rem;
				}
			}

				.qr-codewrap {
					border-radius: $radius-big;
					border: .1rem solid rgba($color-text, .7);
					padding: .7rem;
					width: 5.5rem;
					height: 5.5rem;
					margin-right: 1.1rem;

					.img {
						max-width: 100%;
						max-height: 100%;
					}
				}

	.footer-payment {
		display: flex;
		padding: 2.2rem 0;
		align-items: center;
		justify-content: center;

		strong {
			white-space: nowrap;
		}

		@include media {
			width: 100%;
			max-width: 100%;
			overflow: hidden;
			display: block;
			// flex-flow: row wrap;
			// justify-content: flex-start;

			strong {
				margin-bottom: 2rem;
				display: inline-block;
				vertical-align: middle;
			}

			> .payment-logo {
				height: 2.4rem;
				margin-right: 1rem;
				margin-left: 0;
				margin-bottom: 2rem;
				display: inline-block;
				vertical-align: middle;

				&.breakpoint {
					margin-right: 0;
				}

				&:first-of-type {
					margin-left: 2rem;
				}
			}

			> div {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				.payment-logo {
					height: auto;
					flex: none;
					margin: 0;
					height: 1.8rem;
				}
			}
		}
	}

		.payment-logo {
			height: 3rem;
			margin-left: 2rem;
		}

	.footer-copyright {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 2rem;
		font-size: 1.2rem;
		height: 5.2rem;
		border-top: .1rem solid $color-gray-light;

		@include media {
			height: auto;
			display: block;
			text-align: center;
			padding: 1.5rem 0 2rem;
		}
	}

		.copyright-text {
			@include font-semibold;
			color: $color-text-dark;
		}

		.copyright-pstv {
			display: flex;
			align-items: center;
			font-size: 1.1rem;
			color: $color-text-light;

			@include media {
				margin-top: 2rem;
				justify-content: center;
			}

			.img {
				height: 1.2rem;
				width: 5.5rem;
				margin-left: 1rem;
				margin-bottom: .2rem;
			}
		}
}