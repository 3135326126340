@import "core/index.scss";
.section.header {
	position: relative;
	z-index: 6;

	.header-topbanner {
		padding-bottom: 6.944444444444445%;
		display: block;
		position: relative;
		background-color: $color-bg;

		@include media {
			padding-bottom: 20%;
		}

		.topbanner-image {
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			position: absolute;
		}
	}

	.header-topmenu {
		border-bottom: .1rem solid $color-gray-light;
		padding: 1.7rem 0 0rem;
		position: relative;
		z-index: 3;
		
		.topmenu-wrap {
			position: relative;
			display: flex;
			justify-content: flex-end;
		}

		.topmenu-item {
			@include font-medium;
			position: relative;
			display: inline-block;
			transition: color $transition-hover ease;
			position: relative;
			padding: 1rem 0 1.5rem;

			@include media {
				font-size: 1.2rem;
				padding-top: 0;
				&:before {
					display: none!important;
				}
			}

			+ .topmenu-item {
				margin-left: 2rem;

				@include media {
					margin-left: 1rem;
				}
			}

			&.highlight {
				color: $color-pink;

				&:hover {
					color: darken($color-pink, $darken-ratio);
				}

				&:before {
					@include icon('deco');
					position: absolute;
					bottom: calc(100% - 1.5rem);
					left: 0;
					width: 100%;
					text-align: center;
					font-size: 2.7rem;
				}

				// &.age {
				// 	color: $color-blue;

				// 	&:hover {
				// 		color: darken($color-blue, $darken-ratio);
				// 	}

				// 	&:before {
				// 		@include icon('deco-num');
				// 	}
				// }

				&.internet {
					color: $color-blue;
				}
			}
		}
	}
	
	.header-main {
		position: relative;
		z-index: 2;
		display: flex;
		justify-content: space-between;
		padding: 2.2rem 0 1.2rem;
		margin-bottom: .6rem;

		@include media {
			flex-flow: row wrap;
			align-items: center;
			margin-bottom: 0;
			padding: 1.5rem 0;
		}

		.main-aside {
			display: flex;
			justify-content: flex-start;
			
			@include mediaMin {
				width: calc(50% - 15rem);

				> * + * {
					margin-left: 3rem;
				}
			}

			@include media {
				align-items: center;

				&.controls {
					order: 2;
				}

				&.search {
					width: 100%;
					order: 3;
					margin-top: 2rem;

					@include media {
						margin-top: 1rem;
					}
				}

				> * + * {
					margin-left: 1.5rem;
				}
			}

			&:last-child {
				justify-content: flex-end;
			}
		}

			.aside-item {
				display: flex;
				height: 100%;
				align-items: center;
			}

			.aside-btn {
				display: inline-flex;
				align-items: center;
				flex-flow: row wrap;
				position: relative;

				// &.user {
				// 	&.active {
				// 		color: $color-primary;
				// 	}
				// }

				.btn-count {
					@include font-bold;
					position: absolute;
					left: 100%;
					bottom: 100%;
					font-size: 1.2rem;
					width: 1.8rem;
					height: 1.8rem;
					margin-left: -.6rem;
					margin-bottom: -.7rem;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: $color-pink;
					color: $color-white;
					border-radius: 50%;
					padding-top: .2rem;
	
					span {
						font-size: .8rem;
					}
				}
				
				i {
					font-size: 2.4rem;

					+ * {
						margin-left: 1rem;
					}
				}
			}

		.main-logo {
			@include media {
				order: 1;
			}
		}

			.logo-image {
				height: 9.2rem;
				width: 24.6rem;

				@include media {
					height: 3.7rem;
					width: 9.9rem;
				}
			}

		.aside-search {
			width: 36rem;
			color: $color-text-blue;

			@include media {
				width: 100%;
			}
		}

		.main-childselection {
			.childselection-button {
				color: $color-text-blue;
				max-width: 21rem;

				&.girl {
					background-color: $color-pink-bg;
				}
				&.pregnant {
					background-color: $color-bg-light;
				}

				.btn-icon.post {
					color: $color-text-light;
					opacity: .32;

					@include media {
						margin-right: 0;
					}
				}
			}

				.button-icon {
					font-size: 2.5rem;
					margin-right: 1rem;
				}

				.button-text {
					flex: 1 0 0;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

					.text-selection {
						@include font-regular;
						display: block;
						margin-top: .3rem;
						font-size: 1.3rem;
					}
		}

		.main-button {
			margin-left: 1rem;

			.button-basket-count {
				background-color: $color-blue;
				color: $color-white;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				width: 1.8rem;
				height: 1.8rem;
				font-size: 1.2rem;
				margin-left: .8rem;
				padding-right: .1rem;
			}
		}

		// .main-basketbtn {
		// 	position: relative;
		// }

		.main-secure {
			@include font-bold;
			display: flex;
			align-items: center;
			flex-flow: row wrap;
		}
		
			.secure-icon {
				font-size: 5.6rem;
				margin-left: 1.3rem;
			}
	}

	.header-app-plainheader {
		padding: 1rem 2.4rem 1rem 0;
		min-height: 6rem;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&.has-sidebtn {
			padding-right: 0;
		}
		
		.plainheader-backbtn, .plainheader-sidebtn {
			font-size: 1.6rem;
			width: 2rem;
			height: 2rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.plainheader-backbtn {
			color: $color-text-light;
		}

		.plainheader-sidebtn {
			
			&.share {
				font-size: 1.8rem;
			}
		}

		.plainheader-text {
			padding: 0 1.5rem;
			flex: 1 0 0;
			width: calc(100% - 4rem);
			text-align: center;
		}

		.plainheader-title {
			@include font-semibold;
			font-size: 1.5rem;
			display: inline-block;
			vertical-align: middle;
			line-height: 1.2em;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 100%;
			
			&.has-subtitle {
				max-width: calc(100% - 4rem);
			}
		}

			.title-subtitle {
				color: $color-text-light;
				font-size: 1.1rem;
				margin-left: .8rem;

				&:before {
					content: '(';
				}

				&:after {
					content: ')';
				}
			}
	}

	&.plain {
		border-bottom: .1rem solid $color-gray-light;

		.header-main {
			padding: 1.6rem 0 2.2rem;
			margin-bottom: 0;
			align-items: center;

			@include media {
				display: flex;
				justify-content: space-between;
				flex-flow: row wrap;
				padding: 2rem 0 1rem;
			}

			// .main-logo {}

				.logo-image {
					width: 16.6rem;
					height: 6.2rem;

					@include media {
						width: 10.7rem;
						height: 4rem;
					}
				}
		}

			.main-aside {
				width: 22rem;

				@include media {
					width: auto;
				}
				
				&.backbtn {
					@include media {
						width: 100%;
						margin-bottom: 1rem;
					}
				}
			}
	}

	&.app-plain {
		border-bottom: .1rem solid $color-gray-light;
		// margin-bottom: 2rem;
	}

	&.type-app {
		@include media {
			border-bottom: .1rem solid $color-gray-light;
			padding-top: var(--safe-area-insets-top, env(safe-area-inset-top));
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			background-color: $color-white;

			.header-main {
				.main-aside {
					&.controls {
						justify-content: space-between;
					}
				}

					.controls-right {
						display: flex;
						justify-content: flex-end;
						align-items: center;

						> * + * {
							margin-left: 2rem;
						}
					}
			}

			// .header-mobile-subnav {
			// 	padding-bottom: 0;
			// }
		}
	}
}

.section.header-search {
	@include mediaMin {
		height: 8rem;
	}

	&.invalid {
		.search-input {
			border-color: $color-error;
			color: $color-error;
		}

		.search-icon {
			color: $color-error;
		}
	}

	.search-form {
		position: relative;
		width: 100%;

		&.full {
			.search-submitbtn {
				opacity: 1;
				transform: translate3d(0, 0, 0);
				pointer-events: all;
			}
		}
	}

		.form-innerwrap {
			position: relative;
			width: 100%;
		}

	.search-icon {
		position: absolute;
		left: 0rem;
		top: 0rem;
		width: 3.7rem;
		bottom: 0;
		height: 100%;
		font-size: 1.8rem;
		line-height: 5rem;
		text-align: right;
		pointer-events: none;
		transition: color $transition-hover ease;
		z-index: 3;

		@include media {
			line-height: 4rem;
		}
	}

	.search-input, .search-triggerbtn {
		@include font-semibold;
		position: relative;
		z-index: 2;
		display: block;
		width: 100%;
		height: 5rem;
		font-size: 1.5rem;
		background-color: $color-bg;
		color: inherit;
		border-radius: $radius-general;
		padding: 0 5.8rem 0 4.4rem;
		border: .1rem solid $color-bg;
		transition:
			color $transition-hover ease,
			border-color $transition-hover ease;

		@include media {
			height: 4rem;
			padding-left: 5rem;
			padding-right: 0;
		}

		@include placeholder() {
			color: rgba($color-text-blue, 1);
			transition: color $transition-hover ease;
		}
	}

	.search-submitbtn {
		@include font-bold;
		// color: $color-pink;
		position: absolute;
		right: .5rem;
		top: .5rem;
		bottom: .5rem;
		border-radius: $radius-general;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		opacity: 0;
		pointer-events: none;
		transform: translate3d(.5rem, 0, 0);
		padding: 0 1rem;
		transition: transform $transition-hover ease, opacity $transition-hover ease;
		background-color: rgba($color-white, .8);
		z-index: 3;
	}

	.search-autocomplete {
		position: absolute;
		opacity: 0;
		top: -1.5rem;
		left: -1.5rem;
		right: -1.5rem;
		padding: 7.25rem 1.5rem .75rem;
		border: .1rem solid $color-gray-light;
		background-color: $color-white;
		border-radius: $radius-big;
		z-index: 1;
		box-shadow: $shadow-general;
		transition: opacity $transition-hover ease;
		color: $color-text;

		&.show {
			opacity: 1;
		}
	}

		.autocomplete-outerwrap {
			padding: 1rem 1.2rem 1rem .75rem;
			max-height: 40rem;
			width: calc(100% + 1.2rem + .75rem);
			margin-right: -1.2rem;
			margin-left: -.75rem;
			overflow-x: hidden;

			@include media {
				max-height: calc(100vh - 15rem)
			}
		}

		.autocomplete-innerwrap {
			width: 100%;
		}

		.autocomplete-group {
			+ .autocomplete-group {
				.group-title {
					margin-top: 1.5rem;
				}
			}

			&.type-label {
				+ .autocomplete-group {
					margin-top: .5rem;
				}

				.group-results {
					display: flex;
					justify-content: flex-start;
					flex-wrap: wrap;
					padding-top: .5rem;
				}
					.results-result {
						width: auto;
						margin: 0 1rem .5rem 0;
					}

						.result-link {
							// display: inline;
							// padding: 0;
							@include mediaMin {
								&:hover {
									background-color: $color-bg-dark;
								}
							}
						}
			}
		}

			.group-title {
				@include font-bold;
				margin-bottom: .5rem;
				display: block;
			}

			// .group-results {}

				.results-result {
					width: calc(100% + 1.5rem);
					margin: 0 -.75rem;
				}

					.result-link {
						display: flex;
						align-items: center;
						padding: .5rem .75rem;
						transition: background-color $transition-hover ease;
						border-radius: $radius-general;
						cursor: pointer;

						@include mediaMin {
							&:hover {
								background-color: $color-bg;
							}
						}
					}

					.result-image {
						width: 4rem;
						height: 4rem;
						border-radius: $radius-general;
						margin-right: 1rem;
						box-shadow: $shadow-general;
					}

					.result-title {
						@include font-medium;
						flex: 1 0 0;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						padding: .5rem 0;

						strong {
							@include font-bold;
						}
					}

					.result-type {
						color: $color-text-light;
						text-transform: uppercase;
						font-size: 1.2rem;
						margin-left: 1rem;
					}

	.search-triggerbtn {
		.search-icon {
			line-height: 4.8rem;

			@include media {
				line-height: 3.6rem;
			}
		}
	}
}

.section.header-childmenu {
	position: absolute;
	top: 100%;
	border-radius: $radius-general;
	background-color: $color-white;
	border: .1rem solid $color-gray-light;
	right: 0;
	width: 100%;
	z-index: 2;
	opacity: 0;
	transform: translate3d(0, -.5rem, 0);
	transition: opacity $transition-hover ease, transform $transition-hover ease;
	padding: 2.7rem 2rem 2.7rem;
	box-shadow: $shadow-big;

	@include media {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 0 0 7rem;
		z-index: 10;
		transform: translate3d(0, 2rem, 0);
	}

	&.show {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	.childmenu-outerwrap {
		@include media {
			overflow-x: scroll;
			height: 100%;
			width: 100%;
		}
	}

	.childmenu-selection{
		display: flex;
		justify-content: flex-start;
		flex-flow: row wrap;
		align-items: center;

		@include media {
			display: block;
		}
	}

		.selection-title {
			width: 23.5rem;
			font-size: 1.5rem;
			letter-spacing: .025em;
			line-height: 1.333em;

			@include media {
				display: flex;
				justify-content: space-between;
				border-bottom: .1rem solid $color-gray-light;
				width: calc(100% + 4rem);
				margin: 0 -2rem;
				padding: 0 2rem 0;
				align-items: center;
				height: 6rem;
				margin-top: var(--safe-area-insets-top, env(safe-area-inset-top));
			}
		}

		.selection-opts {
			width: calc(100% - 23.5rem);
			display: flex;
			justify-content: flex-start;
			align-items: center;
			position: relative;
			overflow: hidden;

			@include media {
				width: calc(100% + 4rem);
				margin: 0 -2rem;
			}

			// .simplebar-content-wrapper {
			// 	padding-right: 3rem;
			// }

			&:before, &:after {
				@include pseudo;
				background: $color-white;
				z-index: 4;
				position: absolute;
				top: 0;
				right: 0;
				width: 3rem;
				height: 100%;
				pointer-events: none;
				background: linear-gradient(-90deg, $color-white, rgba($color-white, 80%), rgba($color-white, 0));

				@include media {
					width: 2rem;
				}
			}

			&:before {
				left: 0;
				right: auto;
				transform: rotate(180deg);
			}
		}

			.opts-wrap {
				white-space: nowrap;
				padding: 2rem 0;
				margin-left: 3rem;
				overflow-x: auto;

				@include media {
					margin-left: 0;
				}
			}

			.opts-item {
				@include font-medium;
				display: inline-block;
				width: 9rem;
				text-align: center;
				font-size: 1.4rem;
				color: $color-text-light;
				vertical-align: middle;
				border-radius: $radius-general;
				transition: background-color $transition-hover;
				padding: 1rem .6rem;

				+ .opts-item {
					margin-left: 1rem;
				}
				
				&:last-child {
					margin-right: 3rem;

					@include media {
						margin-right: 2rem;
					}
				}

				&.active {
					background-color: $color-bg;

					.item-icon {
						border-color: $color-text-blue;
					}
				}

				@include mediaMin {
					&:hover {
						background-color: $color-bg;

						.item-icon {
							border-color: $color-text-blue;
						}
					}
				}

				&.all {
					.item-icon {
						background-color: $color-purple-bg;
						border-color: $color-purple-bg;
						color: $color-purple;
						
					}
					
					&.active {
						.item-icon {
							border-color: $color-purple;
						}
					}
					
					@include mediaMin {
						&:hover {
							.item-icon {
								border-color: $color-purple;
							}
						}
					}
				}

				&.boy {
					.item-icon {
						background-color: $color-blue-bg;
						border-color: $color-blue-bg;
						color: $color-blue;
						
					}
					
					&.active {
						.item-icon {
							border-color: $color-blue;
						}
					}
					
					@include mediaMin {
						&:hover {
							.item-icon {
								border-color: $color-blue;
							}
						}
					}
				}

				&.girl {
					.item-icon {
						background-color: $color-pink-bg;
						border-color: $color-pink-bg;
						color: $color-pink;
						
					}
					
					&.active {
						.item-icon {
							border-color: $color-pink;
						}
					}
					
					@include mediaMin {
						&:hover {
							.item-icon {
								border-color: $color-pink;
							}
						}
					}
				}
			}

				.item-icon {
					margin: 0 auto;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 3rem;
					width: 5rem;
					height: 5rem;
					border-radius: 50%;
					background-color: $color-bg;
					border: .2rem dashed $color-bg;
					color: $color-text-blue;
					transition: border-color $transition-hover ease;

					&.small {
						font-size: 1.3rem;
					}
				}

				.item-name {
					display: block;
					width: 100%;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin-top: 1.5rem;
					overflow: hidden;
				}

	.childmenu-bottom {
		position: relative;
		border-top: .1rem solid $color-gray-light;
		margin-top: 2rem;

		&:before {
			@include pseudo;
			position: absolute;
			width: 1.8rem;
			height: 1rem;
			background-image: url('/assets/images/misc/dropdown-decoration.svg');
			background-size: 100% 100%;
			top: -.1rem;
			left: 50%;
			transform: translate3d(-50%, 0, 0) rotate(180deg);
		}
	}

	// .childmenu-add {}

		// .add-form {
		// 	.form-body {
		// 		max-width: 61rem;
		// 	}
		// }

	.childmenu-age {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		padding-bottom: 5.3rem;
		padding-top: 6rem;

		@include media {
			padding: 3rem 0;
		}
	}

		.age-title {
			padding-right: 3.2rem;
			width: 18rem;

			@include media {
				margin: 0 0 2rem;
			}
		}

		.age-controls {
			padding-top: 1.5rem;
			width: 100%;
			
			@include mediaMin {
				padding-left: 18rem;
			}
		}

			.controls-clearbtn {
				text-decoration: underline;
				transition: color $transition-hover ease;

				@include mediaMin {
					&:hover {
						color: $color-text-blue;
					}
				}
			}

	.childmenu-applybtn {
		margin-left: 18rem;
	}

	.childmenu-subcontrols {
		
		@include media {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 3;

			.subcontrols-wrap {
				background-color: $color-white;
				padding: 2rem;
				box-shadow: $shadow-wide;
			}

			.subcontrols-minibtns {
				padding: 0 2rem 1.5rem;
				text-align: right;
			}

				.minibtns-clearbtn {
					@include font-medium;
					text-decoration: underline;
				}
		}

		@include mediaMin {
			padding: 0 3rem;
		}
	}
}

.section.header-searchmenu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $color-white;
	z-index: 10;
	margin-left: 0!important;
	transform: translate3d(0, 2rem, 0);
	transition: opacity $transition-hover ease, transform $transition-hover ease;
	opacity: 0;
	padding: 0 0 2rem;

	&.hidden {
		display: none;
	}

	&.show {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	.searchmenu-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2rem;
		height: 6rem;
		border-bottom: .1rem solid $color-gray-light;
		padding: 0 2rem;
		margin-top: var(--safe-area-insets-top, env(safe-area-inset-top));
	}
	
	.searchmenu-search {
		padding: 0 2rem;
		
		.search-autocomplete {
			position: relative;
			border: none;
			padding: 1rem 0 0;
			left: auto;
			right: auto;
			top: auto;
			box-shadow: none;
		}

			.autocomplete-group {
				&.type-regular {
					// .group-results {}
	
						.results-result {
							margin: 0;
							width: 100%;
	
							+ .results-result {
								border-top: .1rem solid $color-gray-light;
							}
						}
	
							.result-link {
								padding: 1rem 0;
							}
				}
			}

	}
}

.section.header-notifications {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $color-white;
	z-index: 10;
	margin-left: 0!important;
	transform: translate3d(0, 2rem, 0);
	transition: opacity $transition-hover ease, transform $transition-hover ease;
	opacity: 0;
	padding: 0 0 2rem;

	&.show {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	.notifications-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 6rem;
		border-bottom: .1rem solid $color-gray-light;
		padding: 0 2rem;
		margin-top: var(--safe-area-insets-top, env(safe-area-inset-top));
	}

	.notifications-emptymessage {
		margin-top: 2rem;
	}

	.notifications-list {
		padding-bottom: 2rem;
	}

		.list-notification {
			display: flex;
			padding: 1.5rem 2rem;
			border-bottom: .1rem solid $color-gray-light;

			&.unread {
				.notification-icon {
					color: $color-pink;
				}
			}
		}

			.notification-icon {
				font-size: 2rem;
				margin-right: 1rem;
				color: $color-gray-light;
			}

			.notification-text {
				flex: 1 0 0;
				color: $color-text-light;
				font-size: 1.3rem;
				line-height: 1.25em;
			}

				.text-title {
					display: block;
					color: $color-text;
					font-size: 1.5rem;
					margin-bottom: .5rem;
				}

				.text-date {
					@include font-medium;
					margin-top: .5rem;
				}
}

.section.header-usermenu {
	position: absolute;
	right: 0;
	top: 100%;
	background-color: $color-white;
	box-shadow: $shadow-general;
	z-index: 5;
	padding: 2.8rem 0 1rem;
	border: .1rem solid $color-gray-light;
	border-radius: $radius-general;
	opacity: 0;
	transform: translate3d(0, -.5rem, 0);
	transition: opacity $transition-hover ease, transform $transition-hover ease;

	@include media {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		transform: translate3d(0, 2rem, 0);
		z-index: 10;
		padding: 0 0 2rem;
	}

	@include mediaMin {
		&:before {
			@include pseudo;
			position: absolute;
			width: 1.8rem;
			height: 1rem;
			right: 5.5rem;
			bottom: 100%;
			background-image: url('/assets/images/misc/dropdown-decoration.svg');
			background-size: 100% 100%;
		}
	}

	&.show {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	.usermenu-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 2.4rem 0 2.2rem;
		
		@include media {
			margin-top: var(--safe-area-insets-top, env(safe-area-inset-top));
			border-bottom: .1rem solid $color-gray-light;
			height: 6rem;
		}
	}

		.info-left {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			i {
				font-size: 2rem;
				margin-right: 1.5rem;
				margin-bottom: .2rem;
			}
		}

		.info-name {
			@include font-bold;
			font-size: 1.8rem;
			white-space: nowrap;
			overflow: hidden;
			flex: 1 0 0;
			text-overflow: ellipsis;
		}

		.info-surname {
			text-transform: uppercase;
		}

		.info-guesttitle {
			@include font-bold;
			font-size: .7em;
			display: block;
		}
		.info-guestemail {
			font-size: .8em;
			color: $color-blue;
		}

	.usermenu-list {
		margin-top: 2rem;
	}

		.list-item {
			&.apart:not(:first-child) {
				margin-top: 4rem;
			}
		}

			.item-link {
				@include font-semibold;
				width: 100%;
				padding: 1rem 2.4rem 1rem 2.2rem;
				line-height: 2rem;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				transition: color $transition-hover ease, background-color $transition-hover ease;

				@include mediaMin {
					&:hover {
						background-color: $color-bg;
					}
				}

				&.active {
					color: $color-text-blue;
					background-color: $color-bg;
				}

				i {
					font-size: 1.8rem;
					margin-right: 1.3rem;
				}
			}
}

.section.header-mobile-subnav {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: $color-white;
	z-index: 9;
	box-shadow: $shadow-wide;
	padding-bottom: var(--safe-area-insets-bottom, env(safe-area-inset-bottom));

	&.blog {
		.subnav-list {
			padding: 0 5rem;
		}
	}

	.subnav-list {
		display: flex;
		justify-content: space-between;
		padding: 0 1rem;
	}

		.list-item {
			@include font-semibold;
			text-align: center;
			font-size: 1.1rem;
			position: relative;
		}

			.item-icon {
				font-size: 2.3rem;
			}

			.item-link {
				display: block;
				padding: 1.1rem 1rem 0.8rem;
				
				&.active {
					color: $color-text-blue;
				}
			}

			.item-text {
				display: block;
				margin-top: .6rem;
			}

			.item-count {
				@include font-bold;
				top: .2rem;
				right: .4rem;
				position: absolute;
				color: $color-white;
				width: 1.7rem;
				height: 1.7rem;
				font-size: 1rem;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				background-color: $color-pink;
			}

	.subnav-appcontrols {
		@include font-bold;
		background-color: $color-pink;
		color: $color-white;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 1rem 0 calc(1rem + var(--safe-area-insets-bottom, env(safe-area-inset-bottom)));
	}

		.appcontrols-switcher {
			position: relative;
			width: 5rem;
			height: 3rem;
			border-radius: 1.5rem;
			margin: 0 1.7rem;
			overflow: hidden;
			background-color: rgba($color-white, .8);
			box-shadow: $shadow-general;

			&.switched {
				span {
					transform: translate3d(2rem, 0, 0);
				}
			}

			span {
				position: absolute;
				transition: transform $transition-hover ease;
				top: 0;
				left: 0;
				width: 3rem;
				height: 3rem;
				border-radius: $radius-general;
				background-color: $color-white;
				box-shadow: $shadow-general;
				border-radius: 50%;
			}
		}

		.appcontrols-text {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1.1rem;

			i {
				font-size: 1.6rem;
				margin-right: .8rem;
			}
		}
}

.section.header-space-placeholder {
	height: calc(12.1rem + var(--safe-area-insets-top, env(safe-area-inset-top)));
	&.plain {
		height: calc(8.2rem + var(--safe-area-insets-top, env(safe-area-inset-top)));
	}
}